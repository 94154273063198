import useTranslations from '../../utils/use-translations'

type resultOutcomeTextConverterFunctionMap = {
  [key: string]: (outcome: string) => string;
}

const resultOutcomeTextConverters: resultOutcomeTextConverterFunctionMap = {
  egfr: (outcome: string) => {
    const translations = useTranslations()
    const numericalOutcome = Math.round(parseFloat(outcome))

    if (numericalOutcome >= 90) {
      return translations['replacementTokens.derivedResults.values.egfrGreaterThan90']
    }

    return Math.round(parseFloat(outcome)).toString()
  },
  kidneyFunction: (outcome: string) => {
    const translations = useTranslations()

    return `${outcome} ${translations['replacementTokens.results.infection.kidneyFunction.unit']}`
  },
}

export const resultOutcomeText = ({ test, outcome } : { test: string, outcome: string }) => {
  if (resultOutcomeTextConverters[test]) {
    return resultOutcomeTextConverters[test](outcome)
  }

  return outcome
}

export const derivedResultTestText = ({ test } : { test: string}) => {
  const translations = useTranslations()
  const testTranslation = test.toLowerCase()
  const translationPath = `replacementTokens.derivedResults.${testTranslation}`
  return translations[translationPath] || testTranslation
}

export const derivedResultPillBackgroundColour = (test: string, outcome: string | number) => {
  if (test === 'egfr') {
    return Number(outcome) >= 90 ? '#C9FAC8' : '#FFF1BF'
  }

  return undefined
}
