import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import type { Consultation } from '@sh24/admin-api-js'
import { ButtonLink, Callout } from '@sh24/ui-components'
import InlineLink from '../InlineLink/inline-link'
import useTranslations from '../../utils/use-translations'
import replaceTranslationTokens from '../../utils/replace-translation-tokens'
import { formatAppointmentTimes, formatAppointmentDate } from '../../utils/consultation'

const UpdatedAtContainer = styled.div`
  text-align: center;
`

const formatUpdatedAtText = (date: Date, translations: Record<string, string>): string => (
  replaceTranslationTokens({
    translations,
    translationKey: 'consultation.updatedAt',
    values: [format(date, 'dd LLLL'), format(date, 'h:mmaaa')],
  })
)

const ConsultationCard = ({
  consultation,
  translations,
}: {
  consultation: Consultation,
  translations: Record<string, string>,
}) => {
  const blueBackground = ['book_call', 'cold_call', 'call_missed_3_times', 'email_sent', 'cold_call_failed', 'letter_sent'].includes(consultation.state)
  const buttonText = translations[`consultation.callout.${consultation.state}.button`]
  const buttonUrl = ['book_call', 'scheduled', 'cold_call'].includes(consultation.state) ? consultation.schedulingUrl : '/contact-us'
  const appointmentStart = consultation.appointmentStart ? new Date(consultation.appointmentStart) : null
  const appointmentEnd = consultation.appointmentEnd ? new Date(consultation.appointmentEnd) : null
  const showAppointmentCallout = ['scheduled', 'cold_call'].includes(consultation.state) && !!appointmentStart && !!appointmentEnd

  const content = (
    <>
      {showAppointmentCallout && (
        <Callout
          backgroundColour="/tertiary200"
          icon="calendar"
          title={translations['consultation.results_consultation']}
          content={(
            <p>
              {formatAppointmentDate({ appointmentStart })}
              <br />
              {formatAppointmentTimes({ appointmentStart, appointmentEnd })}
            </p>
          )}
        />
      )}
      <p>{translations[`consultation.callout.${consultation.state}.body`]}</p>
      {buttonText && (
        <ButtonLink variation="primary" iconPosition="none" fullWidth>
          <InlineLink
            url={buttonUrl}
            text={buttonText}
            newTab
          />
        </ButtonLink>
      )}
    </>
  )

  return (
    <Callout
      backgroundColour={blueBackground ? '/primary400' : '/white'}
      title={translations[`consultation.callout.${consultation.state}.title`]}
      content={content}
    />
  )
}

const ConsultationPanel = ({ consultation }: { consultation: Consultation }) => {
  const translations = useTranslations()

  return (
    <>
      <UpdatedAtContainer className="mt-md mb-md">
        <p>{formatUpdatedAtText(new Date(consultation.updatedAt), translations)}</p>
      </UpdatedAtContainer>
      <ConsultationCard
        consultation={consultation}
        translations={translations}
      />
    </>
  )
}

export default ConsultationPanel
