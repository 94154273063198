import { format } from 'date-fns'

export const formatAppointmentTimes = ({
  appointmentStart,
  appointmentEnd,
}: {
  appointmentStart: Date,
  appointmentEnd: Date,
}) => {
  const formattedStartHours = format(appointmentStart, 'h')
  const formattedStartMinutes = format(appointmentStart, 'mm') !== '00' ? `:${format(appointmentStart, 'mm')}` : ''
  const formattedEndHours = format(appointmentEnd, 'h')
  const formattedEndMinutes = format(appointmentEnd, 'mm') !== '00' ? `:${format(appointmentEnd, 'mm')}` : ''

  const formattedEndTimeOfDay = format(appointmentEnd, 'aaa')
  const formattedStartTimeOfDay = format(appointmentStart, 'aaa') !== formattedEndTimeOfDay ? ` ${format(appointmentStart, 'bbb')}` : ''

  return `${formattedStartHours}${formattedStartMinutes}${formattedStartTimeOfDay} - ${formattedEndHours}${formattedEndMinutes} ${formattedEndTimeOfDay}`
}

export const formatAppointmentDate = ({
  appointmentStart,
}: {
  appointmentStart: Date
}) => (format(appointmentStart, 'eeee d MMMM'))
