import {
  ChlamydiaTreatmentStatus,
  Result,
  ResultsData,
  StiTestType,
  TestOutcome,
} from '@sh24/admin-api-js'
import groupBy from './group-by'

export type ResultsCardContext = {
  sh24Uid: string,
  infection: string,
  site?: string,
  sampleType: string,
  rprOutcome?: TestOutcome,
  eiaOutcome?: TestOutcome,
  testsPerformed?: 'eia' | 'rpr' | 'eia,rpr',
  outcome: TestOutcome,
  numericalResult?: number,
  updatedAt: number,
  ctOfferState?: ChlamydiaTreatmentStatus
  ctOfferToken?: string
}

const INFECTIONS = {
  chlamydia: 'chlamydia',
  gonorrhoea: 'gonorrhoea',
  hepatitisB: 'hepatitisB',
  hepatitisC: 'hepatitisC',
  hiv: 'hiv',
  syphilis: 'syphilis',
  kidneyFunction: 'kidneyFunction',
}

const SITES = {
  anal: 'anal',
  oral: 'oral',
  urine: 'urine',
  vaginal: 'vaginal',
}

const SAMPLE_TYPE = {
  swab: 'swab',
  urine: 'urine',
  blood: 'blood',
}

const testTypesMap: Record<StiTestType, {
  infection: string,
  site?: string,
  sampleType: string,
  testPerformed?: 'eia' | 'rpr',
}> = {
  'Chlamydia::Anal': { infection: INFECTIONS.chlamydia, site: SITES.anal, sampleType: SAMPLE_TYPE.swab },
  'Chlamydia::Oral': { infection: INFECTIONS.chlamydia, site: SITES.oral, sampleType: SAMPLE_TYPE.swab },
  'Chlamydia::Urine': { infection: INFECTIONS.chlamydia, site: SITES.urine, sampleType: SAMPLE_TYPE.urine },
  'Chlamydia::Vaginal': { infection: INFECTIONS.chlamydia, site: SITES.vaginal, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Anal': { infection: INFECTIONS.gonorrhoea, site: SITES.anal, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Oral': { infection: INFECTIONS.gonorrhoea, site: SITES.oral, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Urine': { infection: INFECTIONS.gonorrhoea, site: SITES.urine, sampleType: SAMPLE_TYPE.urine },
  'Gonorrhoea::Vaginal': { infection: INFECTIONS.gonorrhoea, site: SITES.vaginal, sampleType: SAMPLE_TYPE.swab },
  'HepatitisB::Blood': { infection: INFECTIONS.hepatitisB, sampleType: SAMPLE_TYPE.blood },
  'HepatitisC::Blood': { infection: INFECTIONS.hepatitisC, sampleType: SAMPLE_TYPE.blood },
  'Hiv::Blood': { infection: INFECTIONS.hiv, sampleType: SAMPLE_TYPE.blood },
  'KidneyFunction::SerumCreatinine': { infection: INFECTIONS.kidneyFunction, sampleType: SAMPLE_TYPE.blood },
  'Syphilis::RPR': { infection: INFECTIONS.syphilis, sampleType: SAMPLE_TYPE.blood, testPerformed: 'rpr' },
  'Syphilis::Treponemal': { infection: INFECTIONS.syphilis, sampleType: SAMPLE_TYPE.blood, testPerformed: 'eia' },
}

const getTestTypeDetails = (testType: StiTestType) => testTypesMap[testType]

const latestResult = (results: Array<Result>) => results.sort(
  (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
)[0]

const getSyphilisContextItems = (result: Result) => {
  const { testPerformed } = getTestTypeDetails(result.test)

  const eiaOutcome = testPerformed === 'eia' ? result.outcome : undefined
  const rprOutcome = testPerformed === 'rpr' ? result.outcome : undefined

  return {
    ...(testPerformed ? { testsPerformed: testPerformed } : {}),
    ...(eiaOutcome ? { eiaOutcome } : {}),
    ...(rprOutcome ? { rprOutcome } : {}),
  }
}

const resultsToCardContexts = (results: ResultsData, orderId: string): Array<ResultsCardContext> => {
  const thisOrder = results.orders.find((order) => order.sh24Uid === orderId)
  if (!thisOrder) return []

  if (thisOrder.results.length === 0) {
    return []
  }

  const resultsByTestType = groupBy(thisOrder.results, (result) => result.test)
  const latestResultsByTestType = new Map(Array.from(resultsByTestType).map(
    (entry) => ([entry[0], latestResult(entry[1])]),
  ))
  const latestResults = Array.from(latestResultsByTestType.values())

  return latestResults.map((result) => {
    const { infection, site, sampleType } = getTestTypeDetails(result.test)

    return ({
      sh24Uid: thisOrder.sh24Uid,
      infection,
      ...(site ? { site } : {}),
      sampleType,
      ...(getSyphilisContextItems(result)),
      outcome: result.outcome,
      numericalResult: result.numericalResult,
      linkedDerivedResults: result.linkedDerivedResults,
      updatedAt: result.createdAt.getTime(),
      ...(thisOrder.followOn.chlamydiaTreatment ? {
        ctOfferState: thisOrder.followOn.chlamydiaTreatment.status,
        ctOfferToken: thisOrder.followOn.chlamydiaTreatment.offerToken,
      } : {}),
    })
  })
}

export default resultsToCardContexts
